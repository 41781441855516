export default [
    {
        path: '/driverWithdrawal/record',
        name: 'driverWithdrawalRecord',
        meta: {
            title: '提现记录',
            auth: false
        },
        component: () => import('@/www/pages/driverWithdrawal/record/index.vue')
    },
    {
        path: '/driverWithdrawal/detail',
        name: 'DriverWithdrawalRecordDetail',
        meta: {
            title: '提现详情'
        },
        component: () => import('@/www/pages/driverWithdrawal/record/detail.vue')
    },
    {
        path: '/driverBalance',
        name: 'driverBalance',
        meta: {
            title: '司机余额',
            auth: false
        },
        component: () => import('@/www/pages/driverWithdrawal/driverBalance/index.vue')
    },
    {
        path: '/driverBalance/detail',
        name: 'DriverWithdrawalDetail',
        meta: {
            title: '余额明细'
        },
        component: () => import('@/www/pages/driverWithdrawal/driverBalance/detail.vue')
    },
    {
        path: '/driverWithdrawal/setting',
        name: 'DriverWithdrawalSetting',
        meta: {
            title: '设置提现'
        },
        component: () => import('@/www/pages/driverWithdrawal/setting/index.vue')
    }
]
